import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
// GeneralViews
import NotFound from "@/pages/NotFoundPage.vue";

// Admin pages
// const Profile = () => import(/* webpackChunkName: "common" */ "@/pages/Profile.vue");
// const Notifications = () => import(/* webpackChunkName: "common" */"@/pages/Notifications.vue");
// const Icons = () => import(/* webpackChunkName: "common" */ "@/pages/Icons.vue");
// const Maps = () => import(/* webpackChunkName: "common" */ "@/pages/Maps.vue");
// const Typography = () => import(/* webpackChunkName: "common" */ "@/pages/Typography.vue");
// const TableList = () => import(/* webpackChunkName: "common" */ "@/pages/TableList.vue");
// const Login = () => import(/* webpackChunkName: "common" */ "@/pages/Login.vue");
const Register = () => import(/* webpackChunkName: "common" */ "@/pages/Register.vue");

// const DashboardMensal = () => import(/* webpackChunkName: "dashboard" */"@/pages/DashboardMensal.vue");
// const DashboardSemanal = () => import(/* webpackChunkName: "dashboard" */"@/pages/DashboardSemanal.vue");
// const DashboardDiario = () => import(/* webpackChunkName: "dashboard" */"@/pages/DashboardDiario.vue");
// const DashboardTabela = () => import(/* webpackChunkName: "dashboard" */"@/pages/DashboardTabela.vue");
// const DashboardComissoes = () => import(/* webpackChunkName: "dashboard" */"@/pages/DashboardComissoes.vue");
// const DashboardAfiliados = () => import(/* webpackChunkName: "dashboard" */"@/pages/DashboardAfiliados.vue");
const NewsList = () => import(/* webpackChunkName: "dashboard" */"@/pages/NewsList.vue");
const NewsEdit = () => import(/* webpackChunkName: "dashboard" */"@/pages/NewsEdit.vue");

const WhatsappGroupsImport = () => import(/* webpackChunkName: "dashboard" */"@/pages/WhatsappGroupsImport.vue");
const WhatsappGroupsList = () => import(/* webpackChunkName: "dashboard" */"@/pages/WhatsappGroupsList.vue");
const WhatsappGroupEdit = () => import(/* webpackChunkName: "dashboard" */"@/pages/WhatsappGroupEdit.vue");
const WhatsappMessageList = () => import(/* webpackChunkName: "dashboard" */"@/pages/WhatsappMessageList.vue");
const WhatsappMessageEdit = () => import(/* webpackChunkName: "dashboard" */"@/pages/WhatsappMessageEdit.vue");
const WhatsappMessageView = () => import(/* webpackChunkName: "dashboard" */"@/pages/WhatsappMessageView.vue");
const WhatsappPollList = () => import(/* webpackChunkName: "dashboard" */"@/pages/WhatsappPollList.vue");
const WhatsappPollEdit = () => import(/* webpackChunkName: "dashboard" */"@/pages/WhatsappPollEdit.vue");
const WhatsappPollView = () => import(/* webpackChunkName: "dashboard" */"@/pages/WhatsappPollView.vue");
const WhatsappCampanhaList = () => import(/* webpackChunkName: "dashboard" */"@/pages/WhatsappCampanhaList.vue");
const WhatsappCampanhaEdit = () => import(/* webpackChunkName: "dashboard" */"@/pages/WhatsappCampanhaEdit.vue");
const WhatsappCampanhaView = () => import(/* webpackChunkName: "dashboard" */"@/pages/WhatsappCampanhaView.vue");
const WhatsappRodapeList = () => import(/* webpackChunkName: "dashboard" */"@/pages/WhatsappRodapeList.vue");
const WhatsappRodapeEdit = () => import(/* webpackChunkName: "dashboard" */"@/pages/WhatsappRodapeEdit.vue");

const routes = [
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/mensagem/nova",
    children: [
      {
        path: "noticias",
        name: "Listagem de Notícias",
        component: NewsList
      },
      {
        path: "noticias/edit/:id",
        name: "Alteração de Notícias",
        component: NewsEdit
      },
      {
        path: "noticias/novo",
        name: "Inclusão de Notícias",
        component: NewsEdit
      },

      {
        path: "grupo",
        name: "Listagem de Grupos",
        component: WhatsappGroupsList
      },
      {
        path: "grupo/importar",
        name: "Importar Grupos",
        component: WhatsappGroupsImport
      },
      {
        path: "grupo/novo",
        name: "Inclusão de Grupos",
        component: WhatsappGroupEdit
      },
      {
        path: "grupo/edit/:id",
        name: "Alteração de Grupos",
        component: WhatsappGroupEdit
      },
      {
        path: "mensagem",
        name: "Listagem de Mensagens",
        component: WhatsappMessageList
      },
      {
        path: "mensagem/nova",
        name: "Nova Mensagem",
        component: WhatsappMessageEdit
      },
      {
        path: "mensagem/view/:id",
        name: "Detalhamento de Mensagem",
        component: WhatsappMessageView
      },
      {
        path: "enquete",
        name: "Listagem de Enquetes",
        component: WhatsappPollList
      },
      {
        path: "enquete/nova",
        name: "Nova Enquete",
        component: WhatsappPollEdit
      },
      {
        path: "enquete/view/:id",
        name: "Detalhamento de Enquete",
        component: WhatsappPollView
      },
      {
        path: "campanha",
        name: "Listagem de Campanhas",
        component: WhatsappCampanhaList
      },
      {
        path: "campanha/nova",
        name: "Nova Campanha",
        component: WhatsappCampanhaEdit
      },
      {
        path: "campanha/view/:id",
        name: "Detalhamento de Campanha",
        component: WhatsappCampanhaView
      },
      {
        path: "rodape",
        name: "Listagem de Rodapés para Mensagens",
        component: WhatsappRodapeList
      },
      {
        path: "rodape/novo",
        name: "Novo Rodapé para Mensagens",
        component: WhatsappRodapeEdit
      },
      {
        path: "rodape/:id",
        name: "Editar Rodapé para Mensagens",
        component: WhatsappRodapeEdit
      },
      // {
      //   path: "mensagem/edit",
      //   name: "Inclusão/Alteração de Mensagem - Texto",
      //   component: WhatsappMessageEdit
      // },
      // {
      //   path: "dashboard/semanal",
      //   name: "semanal",
      //   component: DashboardSemanal
      // },
      // {
      //   path: "dashboard/diario",
      //   name: "diario",
      //   component: DashboardDiario
      // },
      // {
      //   path: "dashboard/tabela",
      //   name: "tabela",
      //   component: DashboardTabela
      // },
      // {
      //   path: "dashboard/comissoes",
      //   name: "comissoes",
      //   component: DashboardComissoes
      // },
      // {
      //   path: "dashboard/afiliados",
      //   name: "afiliados",
      //   component: DashboardAfiliados
      // },
      // {
      //   path: "profile",
      //   name: "profile",
      //   component: Profile
      // },
      // {
      //   path: "notifications",
      //   name: "notifications",
      //   component: Notifications
      // },
      // {
      //   path: "icons",
      //   name: "icons",
      //   component: Icons
      // },
      // {
      //   path: "maps",
      //   name: "maps",
      //   component: Maps
      // },
      // {
      //   path: "typography",
      //   name: "typography",
      //   component: Typography
      // },
      {
        path: "register",
        name: "register",
        component: Register
      },
      // {
      //   path: "login",
      //   name: "login",
      //   component: Login
      // }
    ]
  },
  { path: "*", component: NotFound },
];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes;
