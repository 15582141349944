<template>
  <div>
    
    <!-- <router-view :key="$route.fullPath"></router-view> -->
    <!-- <div v-if="authState !== 'signedin'">You are signed out.</div> -->
    <amplify-authenticator>
      <amplify-sign-in
        header-text="Entrar no Guru Admin"
        hide-sign-up
        username-alias="email"
        submit-button-text="Entrar"
        slot="sign-in"
      >
      <div slot="federated-buttons"></div>
      </amplify-sign-in>
      <div v-if="authState === 'signedin' && user">
        <!-- {{ getUsuario() }} -->
        <notifications></notifications>
        <!-- <div>Hello, {{user}}</div> -->
        <router-view :key="$route.fullPath"></router-view>
        <!-- <router-view :key="$route.fullPath"></router-view> -->
      </div>
      <!-- <amplify-sign-out></amplify-sign-out> -->
    </amplify-authenticator>
  </div>
</template>

<script>
  import { onAuthUIStateChange } from '@aws-amplify/ui-components';
  export default {
    methods: {
      disableRTL() {
        if (!this.$rtl.isRTL) {
          this.$rtl.disableRTL();
        }
      },
      toggleNavOpen() {
        let root = document.getElementsByTagName('html')[0];
        root.classList.toggle('nav-open');
      },
      getUsuario() {
        try {
          let user = null
          if (typeof localStorage.ADMIN_AUTH_USER !== "undefined") {
            user = JSON.parse(localStorage.ADMIN_AUTH_USER)
          }
          return user
        } catch (error) {
          console.error(error)
          localStorage.AUTH_ADMIN_USER = null
        }
      }
    },
    mounted() {
      this.$watch('$route', this.disableRTL, { immediate: true });
      this.$watch('$sidebar.showSidebar', this.toggleNavOpen)
    },
    created() {
      this.unsubscribeAuth = onAuthUIStateChange((authState, authData) => {
        this.authState = authState;
        this.user = authData;
        if (this.user) {
          let userGuru = {
            'id': this.user['username'],
            'email': this.user.attributes['email'],
            'nome': this.user.attributes['name'],
            'token': this.user.signInUserSession['idToken']['jwtToken'],
            'profile': this.user.attributes['profile']
          };
          localStorage.ADMIN_AUTH_USER = JSON.stringify(userGuru);
        }
      });
    },
    data() {
      return {
        user: undefined,
        authState: undefined,
        unsubscribeAuth: undefined,
      };
    },
    beforeDestroy() {
      localStorage.ADMIN_AUTH_USER = null
      this.unsubscribeAuth();
    }    
  };
</script>

<style lang="scss">

</style>
