<template>
  <div class="wrapper">
    <side-bar>
      <template slot="links">
        <sidebar-link to="/noticias" name="Notícias" icon="tim-icons icon-paper" v-if="hasPermissao('N')"/>
        <sidebar-link to="/grupo" name="Grupos" icon="tim-icons icon-chat-33" v-if="hasPermissao('G')"/>
        <sidebar-link to="/mensagem" name="Mensagens" icon="tim-icons icon-send" v-if="hasPermissao('M')"/>
        <sidebar-link to="/rodape" name="Rodapés" icon="tim-icons icon-pin" v-if="hasPermissao('M')"/>
        <sidebar-link to="/enquete" name="Enquetes" icon="tim-icons icon-bullet-list-67" v-if="hasPermissao('E')"/>
        <sidebar-link to="/campanha" name="Campanhas" icon="tim-icons icon-bulb-63" v-if="hasPermissao('C')"/>
        
        <!-- <sidebar-link to="/dashboard/semanal" name="Semanal" icon="tim-icons icon-chart-pie-36" v-if="getUsuario()"/>
        <sidebar-link to="/dashboard/diario" name="Diário" icon="tim-icons icon-chart-bar-32" v-if="getUsuario()"/>
        <sidebar-link to="/dashboard/afiliados" name="Afiliados" icon="tim-icons icon-molecule-40" v-if="isMaster"/>
        <sidebar-link to="/dashboard/tabela" name="Primeiro Depósito" icon="tim-icons icon-paper" v-if="getUsuario()"/>
        <sidebar-link to="/dashboard/comissoes" name="Comissões" icon="tim-icons icon-money-coins" v-if="getUsuario()"/> -->
        
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click.native="toggleSidebar">

      </dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<style lang="scss">
</style>
<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./MobileMenu";
export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu
  },
  data() {
      return {
        isMaster: false,
      }
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    getUsuario() {
      try {
        let user = JSON.parse(localStorage.ADMIN_AUTH_USER)
        return user
      } catch (error) {
        console.error(error)
        localStorage.ADMIN_AUTH_USER = null
      }
    },
    hasPermissao(perm) {
      let usr = this.getUsuario()
      if (usr['profile'] && usr['profile'].includes(perm)) {
        return true
      } else {
        return false
      }
    },
    // getUsuario() {
    //   try {
    //     let user = null
    //     if (typeof localStorage.ADMIN_AUTH_USER !== "undefined") {
    //       user = JSON.parse(localStorage.ADMIN_AUTH_USER)
    //     }
    //     return user
    //   } catch (error) {
    //     console.error(error)
    //     localStorage.ADMIN_AUTH_USER = null
    //   }
    // },
    isUserMaster() {
      // let user = this.getUsuario()
      // if (user.id) {
      //   this.$http
      //   .get(this.$API+'dashboard/master/afiliados/'+user.id)
      //   .then(response => {
      //     this.isMaster = response.data && Object.keys(response.data).length > 0
      //   })
      //   .catch(function (error) {
      //         console.error(error)
      //   });   
      // } else {
      //   this.isMaster = false
      // }
      return false
    }
  },
  mounted() {
    this.isUserMaster()
  }
};
</script>
