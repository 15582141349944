<template>
  <nav class="navbar navbar-expand-lg navbar-absolute"
      :class="{'bg-white': showMenu}">
       <!-- :class="{'bg-white': showMenu, 'navbar-transparent': !showMenu}"> -->
    <div class="container-fluid">
      <div class="navbar-wrapper">
        <div class="navbar-toggle d-inline" :class="{toggled: $sidebar.showSidebar}">
          <button type="button"
                  class="navbar-toggler"
                  aria-label="Navbar toggle button"
                  @click="toggleSidebar">
            <span class="navbar-toggler-bar bar1"></span>
            <span class="navbar-toggler-bar bar2"></span>
            <span class="navbar-toggler-bar bar3"></span>
          </button>
        </div>
        <a class="navbar-brand" href="#">{{routeName}}</a>
      </div>
      <button class="navbar-toggler" type="button"
              @click="toggleMenu"
              data-toggle="collapse"
              data-target="#navigation"
              aria-controls="navigation-index"
              aria-label="Toggle navigation">
        <span class="navbar-toggler-bar navbar-kebab"></span>
        <span class="navbar-toggler-bar navbar-kebab"></span>
        <span class="navbar-toggler-bar navbar-kebab"></span>
      </button>

      <collapse-transition>
        <div class="collapse navbar-collapse show" v-show="showMenu">
          <ul class="navbar-nav" :class="$rtl.isRTL ? 'mr-auto' : 'ml-auto'">
            <!-- <div class="search-bar input-group" @click="searchModalVisible = true">
              <button class="btn btn-link" id="search-button" data-toggle="modal" data-target="#searchModal">
                <i class="tim-icons icon-zoom-split"></i>
              </button>
            </div>
            <modal :show.sync="searchModalVisible"
                   class="modal-search"
                   id="searchModal"
                   :centered="false"
                   :show-close="true">
              <input slot="header" v-model="searchQuery" type="text" class="form-control" id="inlineFormInputGroup" placeholder="SEARCH">
            </modal> -->
            <!-- <base-dropdown tag="li"
                           :menu-on-right="!$rtl.isRTL"
                           title-tag="a" class="nav-item">
              <a slot="title" href="#" class="dropdown-toggle nav-link" data-toggle="dropdown" aria-expanded="true">
                <div class="notification d-none d-lg-block d-xl-block"></div>
                <i class="tim-icons icon-sound-wave"></i>
                <p class="d-lg-none">
                  New Notifications
                </p>
              </a>
              <li class="nav-link">
                <a href="#" class="nav-item dropdown-item">Mike John responded to your email</a>
              </li>
              <li class="nav-link">
                <a href="#" class="nav-item dropdown-item">You have 5 more tasks</a>
              </li>
              <li class="nav-link">
                <a href="#" class="nav-item dropdown-item">Your friend Michael is in town</a>
              </li>
              <li class="nav-link">
                <a href="#" class="nav-item dropdown-item">Another notification</a>
              </li>
              <li class="nav-link">
                <a href="#" class="nav-item dropdown-item">Another one</a>
              </li>
            </base-dropdown> -->
            <base-dropdown tag="li"
                           :menu-on-right="!$rtl.isRTL"
                           title-tag="a"
                           class="nav-item"
                           menu-classes="dropdown-navbar">
              <a slot="title" href="#" class="dropdown-toggle nav-link" data-toggle="dropdown" aria-expanded="true">
                
                <div class="photo">
                  <img src="img/anime3.png">
                </div>
                <b class="caret d-none d-lg-block d-xl-block"></b>
                <p class="d-lg-none">
                  {{getUsuario().email}}a
                </p>
              </a>
              <!-- <li class="nav-link">
                <a href="#" class="nav-item dropdown-item">Profile</a>
              </li> -->
              <li class="nav-link d-lg-block">
                <div class="text-center" style="color:grey;">
                  <span>{{getUsuario().email}}</span> <br/>
                  <span>{{getUsuario().nome}}</span>
                </div>
              </li>
              <div class="dropdown-divider"></div>
              <li class="nav-link">
                <!-- <a href="#" class="nav-item dropdown-item">Mudar Senha</a> -->
                <router-link to="/register" class="nav-item dropdown-item">Mudar Senha</router-link>
                <!-- <a class="nav-item dropdown-item" href="https://dy0ygb1g6z4n6.cloudfront.net" target="_self">Mudar Senha</a> -->
              </li>
              <div class="dropdown-divider"></div>
              <li class="nav-link">
                <!-- <a href="#" class="nav-item dropdown-item" @click="logout()">Sair</a> -->
                <amplify-sign-out></amplify-sign-out>
              </li>
            </base-dropdown>
            <!-- <a v-else class="btn btn-primary" href="https://dy0ygb1g6z4n6.cloudfront.net" target="_self">Login</a> -->
          </ul>
        </div>
      </collapse-transition>
    </div>
  </nav>
</template>
<script>
  import { CollapseTransition } from 'vue2-transitions';
  import Modal from '@/components/Modal';

  export default {
    components: {
      CollapseTransition,
      Modal
    },
    computed: {
      routeName() {
        const { name } = this.$route;
        return this.capitalizeFirstLetter(name);
      },
      isRTL() {
        return this.$rtl.isRTL;
      }
    },
    data() {
      return {
        activeNotifications: false,
        showMenu: false,
        searchModalVisible: false,
        searchQuery: '',
        ADMIN_AUTH_USER: 'authuser_guruadmin'
      };
    },
    methods: {
      capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      },
      toggleNotificationDropDown() {
        this.activeNotifications = !this.activeNotifications;
      },
      closeDropDown() {
        this.activeNotifications = false;
      },
      toggleSidebar() {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
      },
      hideSidebar() {
        this.$sidebar.displaySidebar(false);
      },
      toggleMenu() {
        this.showMenu = !this.showMenu;
      },
      getUsuario() {
        try {
          let user = {}
          if (typeof localStorage.ADMIN_AUTH_USER !== "undefined") {
            user = JSON.parse(localStorage.ADMIN_AUTH_USER)
          }
          if (user == null) {
            user = {}
          }
          return user
          // return {'name': 'foo', 'email': 'foo@bar.com'}
        } catch (error) {
          console.error(error)
          localStorage.ADMIN_AUTH_USER = null
        }
      },
      isLogado() {
        let user = this.getUsuario()
        return user != null && user['id'] != null
      },
      logout() {
        localStorage.ADMIN_AUTH_USER = null
        this.$router.push('/#/login')
      }
    }
  };
</script>
<style>
</style>
